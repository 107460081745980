import './App.css';
// import "aos/dist/aos.css";
import 'reactjs-popup/dist/index.css';
import React, {useRef} from 'react';
import Popup from "reactjs-popup";
import toast, { Toaster } from 'react-hot-toast';
import Modal from "./components/Modal";
import {AnimatePresence} from "framer-motion";
import { motion } from "framer-motion";
import {useState} from "react";
import {CopyToClipboard} from 'react-copy-to-clipboard';
import ContactUs from "./ContactUs";
// import Aos from "aos";
// import "aos/dist/aos.css";

const cermelogo = new URL("../public/cermelogo.png", import.meta.url)
const cer = new URL("../public/Background.jpg", import.meta.url)
const House3266 = new URL("../public/3266/3266.png", import.meta.url)
const House3266Layout = new URL("../public/3266/3266Layout.png", import.meta.url)
const House4077 = new URL("../public/4077/4077.png", import.meta.url)
const House4077Layout = new URL("../public/4077/4077Layout.png", import.meta.url)
const House4584 = new URL("../public/4584/4584.png", import.meta.url)
const House4584Layout = new URL("../public/4584/4584Layout.png", import.meta.url)
const House3877 = new URL("../public/3877/3877.png", import.meta.url)
const House3877Layout = new URL("../public/3877/3877Layout.png", import.meta.url)
const House5498 = new URL("../public/5498/5498.png", import.meta.url)
const House5498Layout = new URL("../public/5498/5498Layout.png", import.meta.url)

//Ruko
const Ruko10875 = new URL("../public/10875/10875.png", import.meta.url)
const Ruko10875floor1 = new URL("../public/10875/10875floor1.png", import.meta.url)
const Ruko10875floor2 = new URL("../public/10875/10875floor2.png", import.meta.url)
const Ruko10880 = new URL("../public/10880/10880.png", import.meta.url)
const Ruko10880floor1 = new URL("../public/10880/10880floor1.png", import.meta.url)
const Ruko10880floor2 = new URL("../public/10880/10880floor2.png", import.meta.url)
const Ruko19070 = new URL("../public/19070/19070.png", import.meta.url)
const Ruko19070floor1 = new URL("../public/19070/19070floor1.png", import.meta.url)
const Ruko19070floor2 = new URL("../public/19070/19070floor2.png", import.meta.url)
const Ruko19070floor3 = new URL("../public/19070/19070floor3.png", import.meta.url)

const BlackCermeLogo = new URL("../public/blackcermelogo.png", import.meta.url)

const whatsapplink = "https://wa.me/message/MZISIOOKWS36K1";
const error_load = "Unable To Load This Image"

const notify = () => toast('Copied To Clipboard.');


function App() {

    const [modalOpen, setModalOpen] = useState(false);

    const close = () => setModalOpen(false);
    const open = () => setModalOpen(true);

    console.log("\n" +
        "██╗░░██╗███████╗██╗░░░░░██╗░░░░░░█████╗░░░░  ░█████╗░░█████╗░███╗░░██╗░██████╗░██████╗░░█████╗░████████╗███████╗\n" +
        "██║░░██║██╔════╝██║░░░░░██║░░░░░██╔══██╗░░░  ██╔══██╗██╔══██╗████╗░██║██╔════╝░██╔══██╗██╔══██╗╚══██╔══╝╚════██║\n" +
        "███████║█████╗░░██║░░░░░██║░░░░░██║░░██║░░░  ██║░░╚═╝██║░░██║██╔██╗██║██║░░██╗░██████╔╝███████║░░░██║░░░░░███╔═╝\n" +
        "██╔══██║██╔══╝░░██║░░░░░██║░░░░░██║░░██║██╗  ██║░░██╗██║░░██║██║╚████║██║░░╚██╗██╔══██╗██╔══██║░░░██║░░░██╔══╝░░\n" +
        "██║░░██║███████╗███████╗███████╗╚█████╔╝╚█║  ╚█████╔╝╚█████╔╝██║░╚███║╚██████╔╝██║░░██║██║░░██║░░░██║░░░███████╗\n" +
        "╚═╝░░╚═╝╚══════╝╚══════╝╚══════╝░╚════╝░░╚╝  ░╚════╝░░╚════╝░╚═╝░░╚══╝░╚═════╝░╚═╝░░╚═╝╚═╝░░╚═╝░░░╚═╝░░░╚══════╝\n" +
        "\n" +
        "██╗███████╗  ██╗░░░██╗░█████╗░██╗░░░██╗  ███████╗░█████╗░██╗░░░██╗███╗░░██╗██████╗░\n" +
        "██║██╔════╝  ╚██╗░██╔╝██╔══██╗██║░░░██║  ██╔════╝██╔══██╗██║░░░██║████╗░██║██╔══██╗\n" +
        "██║█████╗░░  ░╚████╔╝░██║░░██║██║░░░██║  █████╗░░██║░░██║██║░░░██║██╔██╗██║██║░░██║\n" +
        "██║██╔══╝░░  ░░╚██╔╝░░██║░░██║██║░░░██║  ██╔══╝░░██║░░██║██║░░░██║██║╚████║██║░░██║\n" +
        "██║██║░░░░░  ░░░██║░░░╚█████╔╝╚██████╔╝  ██║░░░░░╚█████╔╝╚██████╔╝██║░╚███║██████╔╝\n" +
        "╚═╝╚═╝░░░░░  ░░░╚═╝░░░░╚════╝░░╚═════╝░  ╚═╝░░░░░░╚════╝░░╚═════╝░╚═╝░░╚══╝╚═════╝░\n" +
        "\n" +
        "████████╗██╗░░██╗██╗░██████╗\n" +
        "╚══██╔══╝██║░░██║██║██╔════╝\n" +
        "░░░██║░░░███████║██║╚█████╗░\n" +
        "░░░██║░░░██╔══██║██║░╚═══██╗\n" +
        "░░░██║░░░██║░░██║██║██████╔╝\n" +
        "░░░╚═╝░░░╚═╝░░╚═╝╚═╝╚═════╝░")

    const House = useRef(null);
    const About = useRef(null);
    const Contact = useRef(null);

    const HouseClick = () => {
        House.current?.scrollIntoView({behavior: 'smooth'});
    };

    const AboutClick = () => {
        About.current?.scrollIntoView({behavior: 'smooth'});
    };

    const ContactClick = () => {
        Contact.current?.scrollIntoView({behavior: 'smooth'});
    };

    return (
        <>
            <div className="topnav">
                {/*<a className="active" href="#home">Home</a>*/}
                {/*<a href="#news">News</a>*/}
                {/*<a href="#contact">Contact</a>*/}
                {/*<img src={cermelogo} alt="Logo"/>*/}
                <img src={BlackCermeLogo} alt={error_load}/>
                <div className="topnav-right">
                    <a onClick={HouseClick}><i className="fa fa-home fa-2x" aria-hidden="true"></i>
                    </a>
                    <a onClick={AboutClick}><i className="fa fa-info-circle fa-2x" aria-hidden="true"></i></a>
                    {/*<a onClick={ContactClick}><i className="fa fa-phone fa-2x" aria-hidden="true"></i></a>*/}
                </div>
            </div>

            <div className="title">
                <h1>Cerme Square <i id="house" className="fa fa-house fa-1x" aria-hidden="true"/></h1>
                <p>
                    A Place Where You Call <b>Home</b>
                </p>
            </div>


            <div className="row" ref={House}>
                <div className="column" style={{marginLeft: "5%"}}>
                    <div className="card">
                        <a href={House4077}>
                            <img src={House4077} alt={error_load}/>
                        </a>
                        <h1>House Type 40/77</h1>

                        <Popup
                            trigger={<button className="button"> More Info </button>}
                            modal
                            nested
                            lockScroll={true}

                        >
                            {close => (
                                <div className="modal">
                                    {/*<button className="close" onClick={close}>*/}
                                    {/*    &times;*/}
                                    {/*</button>*/}
                                    <div className="content">
                                        <h3>House Type 40/77</h3>
                                        {/*<img src={House4077} alt={error_load} id="house-image"/>*/}
                                        <a href={House4077Layout}>
                                            <img src={House4077Layout} alt={error_load}/>
                                        </a>
                                        <br/>
                                        <br/>
                                        <b>House Description</b>
                                        <p>Build: 40/77</p>
                                        <p>Luas lahan: 7m x 11m</p>
                                        <p>2 Bed</p>
                                        <p>1 Bath</p>
                                        <p>1 Couch</p>
                                        <p id="last-popup">1 Car</p>
                                    </div>

                                </div>
                            )}
                        </Popup>

                    </div>
                </div>

                <div className="column">
                    <div className="card">
                        <a href={House3266}>
                            <img src={House3266} alt={error_load}/>
                        </a>
                        <h1>House Type 32/66</h1>
                        <Popup
                            trigger={<button className="button"> More Info </button>}
                            modal
                            nested
                            lockScroll={true}

                        >
                            {close => (
                                <div className="modal">
                                    <div className="content">
                                        <h3>House Type 32/66</h3>
                                        <a href={House3266Layout}>
                                            <img src={House3266Layout} alt={error_load}/>
                                        </a>
                                        <br/>
                                        <br/>
                                        <b>House Description</b>
                                        <p>Build: 32/66</p>
                                        <p>Luas lahan: 7m x 11m</p>
                                        <p>2 Bed</p>
                                        <p>1 Bath</p>
                                        <p>1 Couch</p>
                                        <p id="last-popup">1 Car</p>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>
                </div>

                <div className="column">
                    <div className="card">
                        <a href={House3877}>
                            <img src={House3877} alt={error_load}/>
                        </a>
                        <h1>House Type 38/77</h1>
                        <Popup
                            trigger={<button className="button"> More Info </button>}
                            modal
                            nested
                            lockScroll={true}

                        >
                            {close => (
                                <div className="modal">
                                    <div className="content">
                                        <h3>House Type 38/77</h3>
                                        <a href={House3877Layout}>
                                            <img src={House3877Layout} alt={error_load}/>
                                        </a>
                                        <br/>
                                        <br/>
                                        <b>House Description</b>
                                        <p>Build: 38/77</p>
                                        <p>Luas lahan: 7m x 11m</p>
                                        <p>2 Bed</p>
                                        <p>1 Bath</p>
                                        <p>1 Couch</p>
                                        <p id="last-popup">1 Car</p>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>
                </div>

                <div className="column">
                    <div className="card">
                        <a href={House4584}>
                            <img src={House4584} alt={error_load}/>
                        </a>
                        <h1>House Type 45/84</h1>
                        <Popup
                            trigger={<button className="button"> More Info </button>}
                            modal
                            nested
                            lockScroll={true}

                        >
                            {close => (
                                <div className="modal">
                                    <div className="content">
                                        <h3>House Type 45/84</h3>
                                        <a href={House4584Layout}>
                                            <img src={House4584Layout} alt={error_load}/>
                                        </a>
                                        <br/>
                                        <br/>
                                        <b>House Description</b>
                                        <p>Build: 45/84</p>
                                        <p>Luas lahan: 7m x 11m</p>
                                        <p>2 Bed</p>
                                        <p>1 Bath</p>
                                        <p>1 Couch</p>
                                        <p id="last-popup">1 Car</p>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>
                </div>

                <div className="column" style={{marginRight: "50%"}}>
                    <div className="card">
                        <a href={House5498}>
                            <img src={House5498} alt={error_load}/>
                        </a>
                        <h1>House Type 54/98</h1>
                        <Popup
                            trigger={<button className="button"> More Info </button>}
                            modal
                            nested
                            lockScroll={true}

                        >
                            {close => (
                                <div className="modal">
                                    <div className="content">
                                        <h3>House Type 54/98</h3>
                                        <a href={House5498Layout}>
                                            <img src={House5498Layout} alt={error_load}/>
                                        </a>
                                        <br/>
                                        <br/>
                                        <b>House Description</b>
                                        <p>Build: 54/98</p>
                                        <p>Luas lahan: 7m x 11m</p>
                                        <p>2 Bed</p>
                                        <p>1 Bath</p>
                                        <p>1 Couch</p>
                                        <p id="last-popup">1 Car</p>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>
                </div>

                {/*RUKO*/}
                <h1>Ruko</h1>



                <div className="column" style={{paddingTop: "10%"}}>
                    <div className="card">
                        <a href={Ruko19070}>
                            <img src={Ruko19070} alt={error_load}/>
                        </a>
                        <h1>Ruko Type 190/70</h1>
                        <Popup
                            trigger={<button className="button"> More Info </button>}
                            modal
                            nested
                            lockScroll={true}

                        >
                            {close => (
                                <div className="modal">
                                    <div className="content">
                                        <h3>Ruko Type 190/70</h3>

                                        <div className="ruko">
                                            <a href={Ruko19070floor1} style={{marginLeft: "7%"}}>
                                                <img src={Ruko19070floor1} alt={error_load} style={{marginLeft: "7%"}}/>
                                            </a>

                                            <a href={Ruko19070floor2} style={{marginLeft: "7%"}}>
                                                <img src={Ruko19070floor2} alt={error_load} style={{marginLeft: "7%"}}/>
                                            </a>

                                            <a href={Ruko19070floor3} style={{marginLeft: "7%"}}>
                                                <img src={Ruko19070floor3} alt={error_load} style={{marginLeft: "7%"}}/>
                                            </a>
                                        </div>

                                        <br/>
                                        <br/>
                                        <b>Ruko Description</b>
                                        <p id="last-popup">Luas lahan: 5m x 14m</p>
                                    </div>
                                </div>
                            )}
                        </Popup>


                    </div>



                </div>
                <div className="column" style={{paddingTop: "10%"}}>
                    <div className="card">
                        <a href={Ruko10880}>
                            <img src={Ruko10880} alt={error_load}/>
                        </a>
                        <h1>Ruko Type 108/80</h1>
                        <Popup
                            trigger={<button className="button"> More Info </button>}
                            modal
                            nested
                            lockScroll={true}

                        >
                            {close => (
                                <div className="modal">
                                    <div className="content">
                                        <h3>Ruko Type 108/80</h3>

                                        <div className="ruko">
                                            <a href={Ruko10880floor1} style={{marginLeft: "7%"}}>
                                                <img src={Ruko10880floor1} alt={error_load} style={{marginLeft: "7%"}}/>
                                            </a>

                                            <a href={Ruko10880floor1} style={{marginLeft: "7%"}}>
                                                <img src={Ruko10880floor2} alt={error_load} style={{marginLeft: "7%"}}/>
                                            </a>

                                        </div>

                                        <br/>
                                        <br/>
                                        <b>Ruko Description</b>
                                        <p>Luas lahan: 5m x 14m</p>
                                    </div>
                                </div>
                            )}
                        </Popup>
                    </div>

                    <div className="column" style={{width: "100%", marginTop: "2rem", paddingTop: "10%"}}>
                        <div className="card">
                            <a href={Ruko10880}>
                                <img src={Ruko10880} alt={error_load}/>
                            </a>
                            <h1>Ruko Type 108/80</h1>
                            <Popup
                                trigger={<button className="button"> More Info </button>}
                                modal
                                nested
                                lockScroll={true}

                            >
                                {close => (
                                    <div className="modal">
                                        <div className="content">
                                            <h3>Ruko Type 108/80</h3>

                                            <div className="ruko">
                                                <a href={Ruko10880floor1} style={{marginLeft: "7%"}}>
                                                    <img src={Ruko10880floor1} alt={error_load} style={{marginLeft: "7%"}}/>
                                                </a>

                                                <a href={Ruko10880floor1} style={{marginLeft: "7%"}}>
                                                    <img src={Ruko10880floor2} alt={error_load} style={{marginLeft: "7%"}}/>
                                                </a>

                                            </div>

                                            <br/>
                                            <br/>
                                            <b>Ruko Description</b>
                                            <p>Luas lahan: 5m x 14m</p>
                                        </div>
                                    </div>
                                )}
                            </Popup>
                        </div>
                    </div>
            </div>
            </div>

            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>

            {/*ABOUT*/}

            <div className="about" ref={About}>
                <div className="about-section">
                    <h1 id="title">About Us</h1>
                    {/*<img id="image" src={cermelogo}*/}
                    {/*     alt="Unable To Display Image"/>*/}
                    <h2 id="description">PT. Suksess Kreasi Bersama
                        didirikan oleh para pendiri
                        <br/> yang sudah <b>berpengalaman</b> di dalam bidang usaha
                        <br/>perumahan dengan beberapa proyek yang perah dikembangkan
                        <br/>di <b>Sidoarjo, Bangil-Pasuruan, Pekanbaru dan beberapa daerah lainya </b></h2>
                </div>
            </div>

            <br/>
            <br/>
            <br/>
            <br/>

            {/*<div className="Contact-us" style={{color: "white", paddingLeft: "20px"}} ref={Contact}>*/}
            {/*    <h1 id="title">Contact Us</h1>*/}
            {/*    <br/>*/}
            {/*    <ContactUs></ContactUs>*/}
            {/*</div>*/}

            {/*<button id="theme" onClick={myFunction}><i className="fa fa-adjust" aria-hidden="true"></i></button>*/}

            <div className="last-page-info" ref={Contact}>
                <p><b>Phone Number:</b> +62 812-1792-2603</p>

                <a href="https://wa.me/message/MZISIOOKWS36K1"><i id="whatsapp"  className="fa fa-whatsapp fa-3x" aria-hidden="true"></i></a>
                <a href="https://mail.google.com/mail/?view=cm&fs=1&to=skbsurabaya1@gmail.com"><i id="gmail" className="fa fa-envelope-o fa-3x" aria-hidden="true"></i></a>
                <br/>
                <h5>Copyright© 2022 Cerme Square </h5>
            </div>

            <AnimatePresence
                // Disable any initial animations on children that
                // are present when the component is first rendered
                initial={false}
                // Only render one component at a time.
                // The exiting component will finish its exit
                // animation before entering component is rendered
                exitBeforeEnter={true}
                // Fires when all exiting nodes have completed animating out
                onExitComplete={() => null}
            >
                {modalOpen && <Modal modalOpen={modalOpen} handleClose={close} />}
            </AnimatePresence>

            <div>
                <motion.button
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    className="save-button"
                    onClick={() => (modalOpen ? close() : open())}
                >
                    Launch modal
                </motion.button>
            </div>

            <br/>
            <br/>

        </>
    );
}



export default App;